import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionFormNextStep,
  actionFormPreviousStep,
  actionFormRecordLead,
} from '../../store/actions/form.actions';
import {
  getAnimatingComponents,
  getFormDemoSubmission,
  getFormLeadAnswers,
  getFormStateSelector,
  getFormTranslations,
  getIsFormOnFirstStep,
  getResponseStateSelector,
  getUIStateSelector,
} from '../../store/selectors';
import Button from '../Button';
import Input, { InputPropsError } from '../Input';
import PhoneInput from '../PhoneInput';
import StepInput from '../StepInput';
import './index.scss';
import equal from 'fast-deep-equal';
import {
  validateAddress,
  validateBusinessName,
  validateCity,
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePhoneNumber,
  validateZip,
} from '../../validators';
import { AnimatePresence, motion } from 'framer-motion';
import APIService from '../../services/api/index.api';
import { removeCharsFromString } from '../../helpers';
import ParentAppService from '../../services/parentApp';
import {
  actionRemoveAnimatingComponents,
  actionUIClose,
  actionUpdateAnimatingComponents,
} from '../../store/actions/ui.actions';
import SubmitScreen from '../SubmitScreen';
import {
  FormsSubmitClientResponse,
  InputType,
} from '../../services/api/types/forms.responses.d';
import Spinner from '../Spinner';
import Textarea from '../Textarea';
import LogoImage from '../LogoImage';
import { sendFBPixelLeadEvent } from '../../services/integrations/facebook-pixel';
import { sendGA4LeadEvent } from '../../services/integrations/ga4';
import { collectWhatConvertsToken } from '../../services/integrations/what-converts';
import { sendGoogleAdsLeadConversion } from '../../services/integrations/google-ads';
import { sendTikTokPixelLeadEvent } from '../../services/integrations/tiktok-pixel';
import { sendLinkedInInsightConversionEvent } from '../../services/integrations/linkedin-insight';
import { sendGoogleTagManagerConversionEvent } from '../../services/integrations/google-tag-manager';

type LastStepInputName =
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'address'
  | 'phoneNumber'
  | 'zip'
  | 'city'
  | 'additionalNotes'
  | 'businessName';

const AVAILABLE_INPUTS: LastStepInputName[] = [
  'firstName',
  'lastName',
  'email',
  'address',
  'phoneNumber',
  'zip',
  'city',
  'additionalNotes',
  'businessName',
];

function StepWindow() {
  const isDemoSubmission = useSelector(getFormDemoSubmission);
  const dispatch = useDispatch();

  const isFirstTime = React.useRef(true);

  const lead = useSelector(getFormLeadAnswers);
  const translations = useSelector(getFormTranslations);
  const { isFromLink, isFixed, isOpen } = useSelector(getUIStateSelector);
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const {
    steps,
    currentStep,
    stepAnswers,
    clientKey,
    phoneNumber,
    settings,
    previousSteps,
  } = useSelector(getFormStateSelector);
  const isOnFirstStep = useSelector(getIsFormOnFirstStep);
  const response = useSelector(getResponseStateSelector('submit-response'));
  const historyLengthBackCounter = React.useRef(0);
  const canLeave = React.useRef(true);
  const lastStepHolderRef =
    React.useRef() as React.MutableRefObject<HTMLDivElement>;

  const componentsAnimating = useSelector(getAnimatingComponents);

  const currentStepData = React.useMemo(() => {
    if (currentStep !== null && steps) {
      const stepFound = steps.find((step) => step.id === currentStep);
      if (stepFound) {
        return stepFound;
      }
    }
    return null;
  }, [currentStep]);

  const activeOptionIds = React.useMemo(() => {
    if (currentStep && steps?.length) {
      const stepFound = steps.find((step) => step.id === currentStep);
      if (stepFound?.inputs?.[0]) {
        if (stepAnswers?.[stepFound.id]?.answers[stepFound.inputs[0].id]) {
          return stepAnswers[currentStep].answers[stepFound.inputs[0].id]
            .optionId;
        }
      }
    }
    return null;
  }, [stepAnswers, currentStep, steps]);

  const activeOptionValue = React.useMemo(() => {
    if (currentStep && steps?.length) {
      const stepFound = steps.find((step) => step.id === currentStep);
      if (stepFound?.inputs?.[0]) {
        if (stepAnswers?.[stepFound.id]?.answers[stepFound.inputs[0].id]) {
          return stepAnswers[currentStep].answers[stepFound.inputs[0].id].value;
        }
      }
    }
    return null;
  }, [stepAnswers, currentStep, steps]);

  const stepIsOptional = React.useMemo(() => {
    if (currentStep && steps?.length) {
      const stepFound = steps.find((step) => step.id === currentStep);
      if (stepFound?.inputs?.[0]) {
        return stepFound.inputs[0].optional;
      }
    }
    return false;
  }, [currentStep, steps]);

  const [inputValid, setInputValid] = React.useState<
    Record<LastStepInputName, boolean>
  >({
    firstName: false,
    lastName: false,
    email: false,
    address: false,
    phoneNumber: false,
    zip: false,
    city: false,
    additionalNotes: false,
    businessName: false,
  });
  const [inputErrors, setInputErrors] = React.useState<
    Record<LastStepInputName, InputPropsError>
  >({
    firstName: [],
    lastName: [],
    email: [],
    address: [],
    phoneNumber: [],
    zip: [],
    city: [],
    additionalNotes: [],
    businessName: [],
  });

  React.useEffect(() => {
    isFirstTime.current = false;
    if (process.env.REACT_APP_USE_NATIVE_BACK === 'true') {
      if (isOpen) {
        if (!isFromLink && !isFixed) {
          history.pushState({ opened: isOpen }, '', window.location.pathname);
        }
        historyLengthBackCounter.current = 0;
      }
    }
  }, [isOpen]);

  const validateInput = React.useCallback(
    (inputName: LastStepInputName, value: string) => {
      const newState: Record<LastStepInputName, boolean> = { ...inputValid };

      switch (inputName) {
        case 'firstName': {
          if (value && validateFirstName(value)) {
            newState.firstName = true;
            setInputErrors({
              ...inputErrors,
              firstName: [],
            });
            dispatch(actionFormRecordLead({ type: 'firstName', value }));
          } else {
            newState.firstName = false;
            if (newState.firstName !== inputValid.firstName) {
              dispatch(
                actionFormRecordLead({ type: 'firstName', value: null }),
              );
            }
          }
          break;
        }
        case 'lastName': {
          if (value && validateLastName(value)) {
            newState.lastName = true;
            setInputErrors({
              ...inputErrors,
              lastName: [],
            });
            dispatch(actionFormRecordLead({ type: inputName, value }));
          } else {
            newState.lastName = false;
            if (newState.lastName !== inputValid.lastName) {
              dispatch(actionFormRecordLead({ type: inputName, value: null }));
            }
          }
          break;
        }
        case 'email': {
          if (value && validateEmail(value)) {
            newState.email = true;
            setInputErrors({
              ...inputErrors,
              email: [],
            });
            dispatch(actionFormRecordLead({ type: inputName, value }));
          } else {
            newState.email = false;
            if (newState.email !== inputValid.email) {
              dispatch(actionFormRecordLead({ type: inputName, value: null }));
            }
          }
          break;
        }
        case 'address': {
          if (value && validateAddress(value)) {
            newState.address = true;
            setInputErrors({
              ...inputErrors,
              address: [],
            });
            dispatch(actionFormRecordLead({ type: inputName, value }));
          } else {
            newState.address = false;
            if (newState.address !== inputValid.address) {
              dispatch(actionFormRecordLead({ type: inputName, value: null }));
            }
          }
          break;
        }
        case 'phoneNumber': {
          if (value && validatePhoneNumber(value)) {
            newState.phoneNumber = true;
            setInputErrors({
              ...inputErrors,
              phoneNumber: [],
            });
            dispatch(actionFormRecordLead({ type: inputName, value }));
          } else {
            newState.phoneNumber = false;
            if (newState.phoneNumber !== inputValid.phoneNumber) {
              dispatch(actionFormRecordLead({ type: inputName, value: null }));
            }
          }
          break;
        }
        case 'zip': {
          if (value && validateZip(value)) {
            newState.zip = true;
            setInputErrors({
              ...inputErrors,
              zip: [],
            });
            dispatch(actionFormRecordLead({ type: inputName, value }));
          } else {
            newState.zip = false;
            if (newState.phoneNumber !== inputValid.phoneNumber) {
              dispatch(actionFormRecordLead({ type: inputName, value: null }));
            }
          }
          break;
        }
        case 'city': {
          if (value && validateCity(value)) {
            newState.city = true;
            setInputErrors({
              ...inputErrors,
              city: [],
            });
            dispatch(actionFormRecordLead({ type: inputName, value }));
          } else {
            newState.city = false;
            if (newState.city !== inputValid.city) {
              dispatch(actionFormRecordLead({ type: inputName, value: null }));
            }
          }
          break;
        }
        case 'additionalNotes': {
          newState.additionalNotes = true;
          setInputErrors({
            ...inputErrors,
            additionalNotes: [],
          });
          if (!value) {
            dispatch(actionFormRecordLead({ type: inputName, value: null }));
          } else {
            dispatch(actionFormRecordLead({ type: inputName, value }));
          }
          break;
        }
        case 'businessName': {
          if (value && validateBusinessName(value)) {
            newState.businessName = true;
            setInputErrors({
              ...inputErrors,
              businessName: [],
            });
            dispatch(actionFormRecordLead({ type: inputName, value }));
          } else {
            newState.businessName = false;
            if (newState.businessName !== inputValid.businessName) {
              dispatch(actionFormRecordLead({ type: inputName, value: null }));
            }
          }
          break;
        }
      }

      if (!equal(newState, inputValid)) {
        setInputValid(newState);
      }
    },
    [inputValid, inputErrors],
  );

  const submitCallback = React.useCallback(
    (calendlyUrl: string | null = null) => {
      if (
        lead?.firstName &&
        lead.lastName &&
        ((settings?.requireAddress && lead.address) ||
          !settings?.requireAddress) &&
        ((settings?.requirePhone && lead.phoneNumber) ||
          !settings?.requirePhone) &&
        ((settings?.requireBusinessName && lead.businessName) ||
          !settings?.requireBusinessName) &&
        lead.email &&
        ((settings?.requireAddress && lead.zip) || !settings?.requireAddress) &&
        stepAnswers
      ) {
        const answers: Record<string, number | number[] | string> = {};
        Object.keys(stepAnswers).forEach((sa) => {
          if (previousSteps.includes(parseInt(sa, 10))) {
            Object.keys(stepAnswers[sa as unknown as number].answers).forEach(
              (ans) => {
                if (
                  stepAnswers[sa as unknown as number].answers[
                    ans as unknown as number
                  ].optionId
                ) {
                  answers[ans as unknown as string] = stepAnswers[
                    sa as unknown as number
                  ].answers[ans as unknown as number].optionId as number[];
                } else if (
                  stepAnswers[sa as unknown as number].answers[
                    ans as unknown as number
                  ].value
                ) {
                  answers[ans as unknown as string] = stepAnswers[
                    sa as unknown as number
                  ].answers[ans as unknown as number].value as number;
                }
              },
            );
          }
        });
        if (clientKey) {
          const wcToken = collectWhatConvertsToken();
          APIService.formsService.submit(
            {
              lead: {
                firstName: lead.firstName,
                lastName: lead.lastName,
                phoneNumber: lead.phoneNumber
                  ? removeCharsFromString(lead.phoneNumber, [' '])
                  : undefined,
                address: lead.address
                  ? `${lead.address}, ${lead.city}, ${lead.zip}`
                  : undefined,
                email: lead.email,
                businessName: lead.businessName || undefined,
                additionalNotes: lead.additionalNotes || undefined,
                calendlyUrl: calendlyUrl || undefined,
              },
              answers,
              clientKey,
              demoSubmission: isDemoSubmission ?? false,
              wcToken: wcToken?.length ? wcToken : undefined,
            },
            'submit-response',
          );

          setSubmitLoading(true);
        }
      }
    },
    [lead, stepAnswers, settings, previousSteps],
  );

  React.useEffect(() => {
    const handleCalendlyEvent = (e: any) => {
      if (e.data.event === 'calendly.event_scheduled') {
        window.Calendly.closePopupWidget();
        dispatch(
          actionUpdateAnimatingComponents({
            components: ['loader'],
          }),
        );
        submitCallback(e.data.payload?.invitee?.uri || null);
      }
    };
    if (settings?.calendlyUrl) {
      window.addEventListener('message', handleCalendlyEvent);
    }
    return () => {
      if (settings?.calendlyUrl) {
        window.removeEventListener('message', handleCalendlyEvent);
      }
    };
  }, [settings, submitCallback]);

  React.useEffect(() => {
    if (response) {
      if (response.status !== 201) {
        ParentAppService.sendCloseMessage({});
        dispatch(actionUIClose());
      } else {
        dispatch(
          actionRemoveAnimatingComponents({
            components: ['loader', 'options', 'title'],
          }),
        );
        // Reset history state
        if (
          process.env.REACT_APP_USE_NATIVE_BACK === 'true' &&
          historyLengthBackCounter.current
        ) {
          canLeave.current = false;
          while (historyLengthBackCounter.current > 0) {
            window.history.back();
            historyLengthBackCounter.current--;
          }
          setTimeout(() => {
            canLeave.current = true;
          }, 500);
        }

        // If needed, track with client integrations
        if (
          lead &&
          ParentAppService.checkIfFormLink() &&
          !ParentAppService.checkIfInsideWebsite()
        ) {
          if (settings?.facebookPixelId) {
            sendFBPixelLeadEvent(settings.facebookPixelId, {
              firstName: lead.firstName,
              lastName: lead.lastName,
              phoneNumber: lead.phoneNumber
                ? removeCharsFromString(lead.phoneNumber, [' '])
                : undefined,
              address: lead.address
                ? `${lead.address}, ${lead.city}, ${lead.zip}`
                : undefined,
              email: lead.email,
              businessName: lead.businessName || undefined,
              additionalNotes: lead.additionalNotes || undefined,
            });
          }
          if (settings?.tikTokPixelId) {
            sendTikTokPixelLeadEvent(lead.email);
          }
          if (
            settings?.googleTagManagerConversionEvent &&
            settings?.googleTagManagerId
          ) {
            sendGoogleTagManagerConversionEvent(
              lead.email,
              settings.googleTagManagerConversionEvent,
            );
          }
          if (settings?.ga4ID) {
            sendGA4LeadEvent(lead.email, settings.ga4ID);
          }
          if (settings?.linkedInConversionId) {
            sendLinkedInInsightConversionEvent(
              settings.linkedInConversionId,
              lead.email,
            );
          }
          if (settings?.googleAdsTagId && settings?.googleAdsDestinationId) {
            sendGoogleAdsLeadConversion(
              settings.googleAdsTagId,
              settings.googleAdsDestinationId,
            );
          }
        }

        // Redirect
        if (
          response?.response?.data?.redirectUrl ||
          settings?.resultScreenRedirect?.length
        ) {
          setTimeout(() => {
            window.location.href =
              response?.response?.data?.redirectUrl ||
              (settings?.resultScreenRedirect as string);
          }, 0);
        }
      }
    }
  }, [response]);

  React.useEffect(() => {
    setInputErrors({
      firstName: [],
      lastName: [],
      email: [],
      address: [],
      phoneNumber: [],
      zip: [],
      city: [],
      additionalNotes: [],
      businessName: [],
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 900);
  }, [currentStep]);

  const TITLE_DELAY = 0.4;

  const responseData = response?.response as FormsSubmitClientResponse;

  React.useEffect(() => {
    const onBackClick = (e: PopStateEvent) => {
      if (response?.status === 201 || isOnFirstStep) {
        // Check if shuld exit (is in widget mode)
        const shouldExit =
          ((isFixed || isFromLink) && !isOnFirstStep && !response) ||
          (!isFromLink && !isFixed);
        if (shouldExit && canLeave.current) {
          // Exit tapform
          ParentAppService.sendCloseMessage({});
          dispatch(actionUIClose());
        }
      } else {
        // Go back
        dispatch(actionFormPreviousStep());
        if (historyLengthBackCounter.current) {
          historyLengthBackCounter.current--;
        }
      }
    };
    if (process.env.REACT_APP_USE_NATIVE_BACK === 'true') {
      window.addEventListener('popstate', onBackClick, false);
    }
    return () => {
      if (process.env.REACT_APP_USE_NATIVE_BACK === 'true') {
        window.removeEventListener('popstate', onBackClick);
      }
    };
  }, [response, isOnFirstStep, isFixed, isFromLink, canLeave]);

  function recordHistoryPush(data: any) {
    if (process.env.REACT_APP_USE_NATIVE_BACK === 'true') {
      history.pushState(data, '', window.location.pathname);
      historyLengthBackCounter.current++;
    }
  }

  if (currentStepData === null || stepAnswers === null) {
    return null;
  }

  function focusNextInput(e: any) {
    if (e.key !== 'Enter') return;
    const currentInput = e.target;
    if (lastStepHolderRef.current) {
      // Check if lastStepHolderRef exists and currentInput is a valid element
      if (!lastStepHolderRef?.current || !currentInput) return;

      // Get all input and textarea elements inside lastStepHolderRef
      const inputs = Array.from(
        lastStepHolderRef.current.querySelectorAll('input, textarea'),
      )
        .filter((el) => el.getAttribute('name'))
        .filter((el) =>
          AVAILABLE_INPUTS.includes(
            el.getAttribute('name') as LastStepInputName,
          ),
        );

      // Find the index of the current input in the list
      const currentIndex = inputs.indexOf(currentInput);

      // Check if the next input exists
      if (currentIndex >= 0 && currentIndex < inputs.length - 1) {
        const nextInput = inputs[currentIndex + 1];
        if (nextInput) {
          // Focus the next input
          (nextInput as any).focus();
        }
      }
    }
  }

  return (
    <div
      className={`step-window-component-container ${
        (!currentStepData.isFinalStep &&
          currentStepData?.inputs?.[0]?.inputType) ||
        ''
      }`}
    >
      <AnimatePresence mode="wait">
        {!submitLoading && !responseData ? (
          <>
            <AnimatePresence
              mode="wait"
              onExitComplete={() => {
                dispatch(
                  actionRemoveAnimatingComponents({
                    components: ['title'],
                  }),
                );
              }}
            >
              <motion.h1
                initial={{
                  opacity: 0,
                  translateY: '65px',
                }}
                animate={{
                  opacity: 1,
                  translateY: '0%',
                  transition: {
                    duration: 0.4,
                    ease: 'anticipate',
                    delay: isFirstTime.current ? 0.4 : 0,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    duration: 0.7,
                    delay: 0,
                    ease: 'anticipate',
                  },
                }}
                onLoad={() => {
                  dispatch(
                    actionUpdateAnimatingComponents({
                      components: ['title'],
                    }),
                  );
                }}
                onAnimationComplete={() => {
                  dispatch(
                    actionRemoveAnimatingComponents({
                      components: ['title'],
                    }),
                  );
                }}
                key={`${currentStepData.id}_title_${currentStepData.title}`}
                className={`step-window-component-title ${
                  currentStepData.description?.length ? '' : 'no-description'
                } ${currentStepData.isFinalStep ? 'final-step' : ''}`}
              >
                {currentStepData.title}
              </motion.h1>
              {currentStepData.description?.length ? (
                <motion.p
                  initial={{
                    opacity: 0,
                    translateY: '65px',
                  }}
                  animate={{
                    opacity: 1,
                    translateY: '0%',
                    transition: {
                      duration: 0.4,
                      ease: 'anticipate',
                      delay: isFirstTime.current ? 0.5 : 0,
                    },
                  }}
                  exit={{
                    opacity: 0,
                    transition: {
                      duration: 0.7,
                      delay: 0,
                      ease: 'anticipate',
                    },
                  }}
                  onLoad={() => {
                    dispatch(
                      actionUpdateAnimatingComponents({
                        components: ['description'],
                      }),
                    );
                  }}
                  onAnimationComplete={() => {
                    dispatch(
                      actionRemoveAnimatingComponents({
                        components: ['description'],
                      }),
                    );
                  }}
                  key={`${currentStepData.id}_desc_${currentStepData.description}`}
                  className="step-window-component-description"
                >
                  {currentStepData.description}
                </motion.p>
              ) : null}
            </AnimatePresence>
            <div
              className="step-window-component-inputs"
              ref={lastStepHolderRef}
            >
              <AnimatePresence mode="wait">
                {currentStepData.isFinalStep ? (
                  <div
                    className="step-window-component-final-container"
                    key="final-container"
                  >
                    <div className="step-window-component-final-name">
                      <Input
                        key={'firstName_input'}
                        className="step-window-component-final-first-name"
                        type={'text'}
                        placeholder=""
                        label={
                          translations
                            ? translations['first-name-input']
                            : 'First Name'
                        }
                        name="firstName"
                        tooltip={
                          translations
                            ? translations['first-name-input']
                            : 'First Name'
                        }
                        defaultValue={lead?.firstName || ''}
                        errors={inputErrors.firstName}
                        valid={inputValid.firstName}
                        onChange={(val) => {
                          validateInput('firstName', val);
                        }}
                        motionDelay={TITLE_DELAY + 0.1}
                        motionDuration={0.65}
                        defaultFocused={true}
                        onBlur={(e) => {
                          if (!inputValid.firstName) {
                            setInputErrors({
                              ...inputErrors,
                              firstName: ['First Name must not be empty!'],
                            });
                          }
                        }}
                        onKeyUp={focusNextInput}
                      />
                      <Input
                        key={'lastName_input'}
                        className="step-window-component-final-last-name"
                        type={'text'}
                        placeholder=""
                        label={
                          translations
                            ? translations['last-name-input']
                            : 'Last Name'
                        }
                        tooltip={
                          translations
                            ? translations['last-name-input']
                            : 'Last Name'
                        }
                        name="lastName"
                        defaultValue={lead?.lastName || ''}
                        errors={inputErrors.lastName}
                        valid={inputValid.lastName}
                        onChange={(val) => {
                          validateInput('lastName', val);
                        }}
                        motionDelay={TITLE_DELAY + 0.15}
                        motionDuration={0.6}
                        onBlur={(e) => {
                          if (!inputValid.lastName) {
                            setInputErrors({
                              ...inputErrors,
                              lastName: ['Last Name must not be empty!'],
                            });
                          }
                        }}
                        onKeyUp={focusNextInput}
                      />
                    </div>
                    <Input
                      className="step-window-component-final-address"
                      type={'email'}
                      key={'email_input'}
                      placeholder=""
                      label={
                        translations ? translations['email-input'] : 'Email'
                      }
                      tooltip={
                        translations ? translations['email-input'] : 'Email'
                      }
                      name="email"
                      defaultValue={lead?.email || ''}
                      errors={inputErrors.email}
                      valid={inputValid.email}
                      onChange={(val) => {
                        validateInput('email', val);
                      }}
                      motionDelay={TITLE_DELAY + 0.25}
                      motionDuration={0.55}
                      onBlur={(e) => {
                        if (!inputValid.email) {
                          setInputErrors({
                            ...inputErrors,
                            email: ['Email must be valid!'],
                          });
                        }
                      }}
                      onKeyUp={focusNextInput}
                    />
                    {settings?.requireBusinessName ? (
                      <Input
                        className="step-window-component-final-address"
                        type={'text'}
                        key={'business_name_input'}
                        placeholder=""
                        label={
                          translations
                            ? translations['business-name-input']
                            : 'Business Name'
                        }
                        tooltip={
                          translations
                            ? translations['business-name-input']
                            : 'Business Name'
                        }
                        name="businessName"
                        defaultValue={lead?.businessName || ''}
                        errors={inputErrors.businessName}
                        valid={inputValid.businessName}
                        onChange={(val) => {
                          validateInput('businessName', val);
                        }}
                        motionDelay={TITLE_DELAY + 0.25}
                        motionDuration={0.55}
                        onBlur={(e) => {
                          if (!inputValid.businessName) {
                            setInputErrors({
                              ...inputErrors,
                              businessName: ['Busines Name must not be empty!'],
                            });
                          }
                        }}
                        onKeyUp={focusNextInput}
                      />
                    ) : null}
                    {settings?.requireAddress ? (
                      <>
                        <Input
                          className="step-window-component-final-address"
                          type={'text'}
                          key={'address_input'}
                          placeholder=""
                          label={
                            translations
                              ? translations['address-input']
                              : 'Address'
                          }
                          tooltip={
                            translations
                              ? translations['address-input']
                              : 'Address'
                          }
                          name="address"
                          defaultValue={lead?.address || ''}
                          errors={inputErrors.address}
                          valid={inputValid.address}
                          onChange={(val) => {
                            validateInput('address', val);
                          }}
                          motionDelay={TITLE_DELAY + 0.35}
                          motionDuration={0.5}
                          onBlur={(e) => {
                            if (!inputValid.address) {
                              setInputErrors({
                                ...inputErrors,
                                address: ['Address must not be empty!'],
                              });
                            }
                          }}
                          onKeyUp={focusNextInput}
                        />
                        <div className="step-window-component-final-single-row">
                          <Input
                            className="step-window-component-final-city"
                            type={'text'}
                            key={'city_input'}
                            placeholder=""
                            label={
                              translations ? translations['city-input'] : 'City'
                            }
                            tooltip={
                              translations ? translations['city-input'] : 'City'
                            }
                            name="city"
                            defaultValue={lead?.city || ''}
                            errors={inputErrors.city}
                            valid={inputValid.city}
                            onChange={(val) => {
                              validateInput('city', val);
                            }}
                            motionDelay={TITLE_DELAY + 0.35}
                            motionDuration={0.5}
                            onBlur={(e) => {
                              if (!inputValid.city) {
                                setInputErrors({
                                  ...inputErrors,
                                  city: ['City must not be empty!'],
                                });
                              }
                            }}
                            onKeyUp={focusNextInput}
                          />
                          <Input
                            className="step-window-component-final-zip"
                            type={'number'}
                            key={'zip_input'}
                            placeholder=""
                            label={
                              translations ? translations['zip-input'] : 'Zip'
                            }
                            tooltip={
                              translations ? translations['zip-input'] : 'Zip'
                            }
                            name="zip"
                            defaultValue={lead?.zip || ''}
                            errors={inputErrors.zip}
                            valid={inputValid.zip}
                            onChange={(val) => {
                              validateInput('zip', val);
                            }}
                            motionDelay={TITLE_DELAY + 0.35}
                            motionDuration={0.5}
                            onBlur={(e) => {
                              if (!inputValid.zip) {
                                setInputErrors({
                                  ...inputErrors,
                                  zip: ['Zip must be 5 digit number!'],
                                });
                              }
                            }}
                            onKeyUp={focusNextInput}
                          />
                        </div>
                      </>
                    ) : null}
                    {settings?.requirePhone ? (
                      <PhoneInput
                        key={'phoneNumber_input'}
                        name={'phoneNumber'}
                        className="step-window-component-final-phone"
                        errors={inputErrors.phoneNumber}
                        valid={inputValid.phoneNumber}
                        defaultValue={lead?.phoneNumber || ''}
                        onChange={(val) => {
                          validateInput('phoneNumber', val);
                        }}
                        onBlur={(e) => {
                          if (!inputValid.phoneNumber) {
                            setInputErrors({
                              ...inputErrors,
                              phoneNumber: ['Phone is required!'],
                            });
                          }
                        }}
                        label={
                          translations
                            ? translations['phone-number-input']
                            : 'Phone Number'
                        }
                        motionDelay={TITLE_DELAY + 0.4}
                        motionDuration={0.45}
                        onKeyUp={focusNextInput}
                      />
                    ) : null}
                    {settings?.requireAdditionalNotes ? (
                      <Textarea
                        className="step-window-component-final-additional-notes"
                        key={'additionalNotes_textarea'}
                        placeholder=""
                        label={
                          translations
                            ? translations['additional-notes-input']
                            : 'Additional Notes'
                        }
                        name="additionalNotes"
                        defaultValue={lead?.additionalNotes || ''}
                        errors={inputErrors.additionalNotes}
                        valid={inputValid.additionalNotes}
                        onChange={(val) => {
                          validateInput('additionalNotes', val);
                        }}
                        motionDelay={TITLE_DELAY + 0.45}
                        motionDuration={0.5}
                        maxLength={1000}
                        onKeyUp={focusNextInput}
                      />
                    ) : null}
                    <motion.div
                      initial={{ opacity: 0, translateY: '30px' }}
                      animate={{
                        opacity: 1,
                        translateY: '0%',
                        transition: {
                          duration: 0.45,
                          delay: TITLE_DELAY + 0.47,
                          ease: 'anticipate',
                        },
                      }}
                      exit={{
                        opacity: 0,
                        transition: {
                          delay: 0,
                          duration: 0.35,
                          ease: 'anticipate',
                        },
                      }}
                      className="step-window-component-final-notice"
                    >
                      <span className="step-window-component-final-notice-title">
                        {translations?.['disclaimer-title'] || ''}
                      </span>
                      <span className="step-window-component-final-notice-description">
                        {translations?.['disclaimer-text'] || ''}
                      </span>
                    </motion.div>
                  </div>
                ) : currentStepData.inputs?.[0] ? (
                  <StepInput
                    stepId={currentStepData.id}
                    inputId={currentStepData.inputs[0].id}
                    options={currentStepData.inputs[0].options}
                    inputType={currentStepData.inputs[0].inputType}
                    inputDesignType={currentStepData.inputs[0].designType}
                    inputViewType={currentStepData.inputs[0].viewType}
                    inputQuestion={currentStepData.inputs[0].question}
                    inputLabel={
                      currentStepData.inputs[0].inputLabel ??
                      currentStepData.inputs[0].question
                    }
                    inputLabelSecond={
                      currentStepData.inputs[0].inputLabelSecond ??
                      currentStepData.inputs[0].question
                    }
                    optionalStep={stepIsOptional}
                    onSelect={(optionId) => {
                      if (
                        currentStepData.inputs?.[0].inputType ===
                          InputType.SINGLE_SELECT &&
                        (!stepIsOptional ||
                          (stepIsOptional &&
                            !activeOptionIds?.includes(optionId)))
                      ) {
                        dispatch(
                          actionUpdateAnimatingComponents({
                            components: ['options', 'title'],
                          }),
                        );
                        dispatch(
                          actionFormNextStep({
                            current: {
                              stepId: currentStepData.id,
                              optionId: [optionId],
                            },
                          }),
                        );
                        recordHistoryPush({ stepFinished: currentStepData.id });
                      }
                    }}
                    motionDelay={0}
                    isFirstTime={isFirstTime.current}
                    key={'step-input'}
                    onProceed={() => {
                      if (currentStepData.isFinalStep) {
                        dispatch(
                          actionUpdateAnimatingComponents({
                            components: ['loader'],
                          }),
                        );
                        submitCallback();
                      } else {
                        if (
                          !componentsAnimating.length &&
                          (activeOptionIds?.length || activeOptionValue)
                        ) {
                          dispatch(
                            actionUpdateAnimatingComponents({
                              components: ['title', 'options'],
                            }),
                          );
                          dispatch(
                            actionFormNextStep({
                              current: {
                                stepId: currentStepData.id,
                                optionId: activeOptionIds?.length
                                  ? activeOptionIds
                                  : null,
                              },
                            }),
                          );
                          recordHistoryPush({
                            stepFinished: currentStepData.id,
                          });
                        }
                      }
                    }}
                  />
                ) : null}
              </AnimatePresence>
            </div>
            <div className="step-window-component-shadow" />
            <div
              style={{ animationDelay: `${TITLE_DELAY + 0.5}s` }}
              className="step-window-component-submit-container"
            >
              <Button
                className="step-window-component-submit"
                onClick={() => {
                  if (currentStepData.isFinalStep) {
                    if (settings?.calendlyUrl) {
                      window.Calendly.initPopupWidget({
                        url: settings?.calendlyUrl,
                        prefill: {
                          firstName: lead?.firstName,
                          lastName: lead?.lastName,
                          location: lead?.phoneNumber,
                          email: lead?.email,
                          name: `${lead?.firstName} ${lead?.lastName}`,
                        },
                        utm: {},
                      });
                    } else {
                      dispatch(
                        actionUpdateAnimatingComponents({
                          components: ['loader'],
                        }),
                      );
                      submitCallback();
                    }
                  } else {
                    if (
                      !componentsAnimating.length &&
                      (activeOptionIds?.length ||
                        activeOptionValue ||
                        stepIsOptional)
                    ) {
                      dispatch(
                        actionUpdateAnimatingComponents({
                          components: ['title', 'options'],
                        }),
                      );
                      dispatch(
                        actionFormNextStep({
                          current: {
                            stepId: currentStepData.id,
                            optionId: activeOptionIds?.length
                              ? activeOptionIds
                              : null,
                          },
                        }),
                      );
                      recordHistoryPush({ stepFinished: currentStepData.id });
                    }
                  }
                }}
                disabled={
                  !stepAnswers[currentStepData.id].answered && !stepIsOptional
                }
                text={currentStepData.submitButtonText}
              />
            </div>
          </>
        ) : responseData && !settings?.resultScreenRedirect?.length ? (
          <SubmitScreen
            logoImageId={responseData.data.imageId}
            title={responseData.data.title}
            description={responseData.data.description}
            price={responseData.data.minPriceFrom}
            clientKey={clientKey as string}
            leadId={responseData.data.leadId}
            phoneNumber={phoneNumber as string}
            phoneCtaText={responseData.data.buttonText}
            startingFromText={responseData.data.priceLabel}
            resultScreenButtonRedirect={settings?.resultScreenButtonRedirect}
            startingFromDisclaimer={responseData.data.priceDisclaimer || null}
          />
        ) : (
          <motion.div
            initial={{ opacity: 0, scale: 0.6 }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: {
                duration: 0.5,
                delay: 0,
                ease: 'anticipate',
              },
            }}
            exit={{
              opacity: 0,
              scale: 0.6,
              transition: {
                duration: 0.5,
                ease: 'anticipate',
                delay: 0,
              },
            }}
            className="step-window-component-loading-container"
          >
            {settings?.resultScreenRedirect ? (
              <LogoImage
                className="step-window-component-loading-logo-spinner"
                animate
              />
            ) : (
              <Spinner className="step-window-component-loading-spinner" />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default StepWindow;
