import { DesignType, ViewType } from './input';

export enum StepType {
  QUESTIONS = 'questions',
  INFORMATION = 'information',
}

export enum InputType {
  SINGLE_SELECT = 'single-select',
  MULTIPLE_SELECT = 'multiple-select',
  PHONE_INPUT = 'phone-input',
  TEXT_INPUT = 'text-input',
  TEXTAREA_INPUT = 'textarea-input',
  EMAIL_INPUT = 'email-input',
  DATE_INPUT = 'date-input',
  DATE_RANGE_INPUT = 'date-range-input',
  NUMBER_INPUT = 'number-input',
  ADDRESS_INPUT = 'address-input',
}

export interface FormEntityTranslations {
  'first-name-input': string;
  'last-name-input': string;
  'email-input': string;
  'address-input': string;
  'city-input': string;
  'zip-input': string;
  'phone-number-input': string;
  'additional-notes-input': string;
  'disclaimer-title': string;
  'disclaimer-text': string;
  'phone-header-cta-label-1': string;
  'phone-header-cta-label-2': string;
  'result-phone-call': string;
  'result-starting-from': string;
  'result-starting-from-disclaimer': string;
  'result-below-phone': string;
  'business-name-input': string;
}

export interface FormsLoadClientStepOptionPrice {
  minPriceRule: number;
  title: string;
  description: string;
  imageId: number | null;
  id: number;
}

export interface FormsLoadClientStepOption {
  id: number;
  order: number;
  value: string;
  imageId: number | null;
  price: FormsLoadClientStepOptionPrice | null;
  nextStepId: number | null;
}

export interface FormsLoadClientStepsInput {
  id: number;
  question: string;
  order: number;
  inputType: InputType;
  options: FormsLoadClientStepOption[] | null;
  inputLabel: string | null;
  inputLabelSecond: string | null;
  designType: DesignType;
  viewType: ViewType;
  optional?: boolean;
}

export interface FormsLoadClientStep {
  id: number;
  title: string;
  description: string;
  isFinalStep: boolean;
  order: number;
  submitButtonText: string;
  stepType: StepType;
  inputs: FormsLoadClientStepsInput[] | null;
  nextStepId: number | null;
}

export enum FormLoadClientPopupType {
  SIMPLE = 'simple',
}

export interface FormsLoadClientPopup {
  type: FormLoadClientPopupType;
  title: string;
  label: string;
  proceedText: string;
  cancelText: string;
  triggerTime: number;
}

export interface FormsLoadClientSettings {
  developmentMode: boolean;
  requireAddress: boolean;
  requirePhone: boolean;
  requireAdditionalNotes: boolean;
  requireBusinessName: boolean;
  ga4ID: string | null;
  facebookPixelId: string | null;
  calendlyUrl: string | null;
  autoConsent: boolean;
  resultScreenButtonRedirect: string | null;
  resultScreenRedirect: string | null;
  collectUTM: boolean;
  whatConvertsProfileId: string | null;
  googleAdsTagId: string | null;
  googleAdsDestinationId: string | null;
  tikTokPixelId: string | null;
  linkedInPartnerId: string | null;
  linkedInConversionId: string | null;
  googleTagManagerId: string | null;
  googleTagManagerConversionEvent: string | null;
  dateFormat: string;
}

export interface FormStyleDto {
  id: string;
  backgroundColor: string;
  buttonBackgroundColor: string;
  buttonTextColor: string;
  buttonBorderRadius: number;
  inputBackgroundColor: string;
  inputTextColor: string;
  titleColor: string;
  logoImage: ImageDto | null;
  disableBranding: boolean;
}

export interface FormWidgetDto {
  id: string;
  widgetTitle: string;
  widgetButtonText: string;
  widgetButtonBackgroundColor: string;
  widgetButtonTextColor: string;
  widgetLabel: string;
  defaultVisible: boolean;
  offsetBottom: number;
  hideOnBottom: boolean;
  hideShowOnScroll: boolean;
  widgetTheme: FormWidgetTheme;
  image: ImageDto | null;
}

export interface PlatformSettingsDto {
  loadingWatermark: boolean;
}

export interface FormsLoadClientResponse {
  data: {
    logoImageId: number;
    brandColor: string;
    widgetTitle: string;
    widgetButtonText: string;
    phoneNumber: string | null;
    steps: FormsLoadClientStep[] | null;
    clientKey: string;
    popups: FormsLoadClientPopup[];
    settings: FormsLoadClientSettings;
    translations: FormEntityTranslations;
    style: FormStyleDto;
    widget: FormWidgetDto;
    platformSettings: PlatformSettingsDto;
  };
}

export interface FormsSubmitClientResponse {
  data: {
    imageId: number;
    title: string;
    description: string;
    label: string;
    minPriceFrom: number;
    websiteHref: string;
    leadId: number;
    redirectUrl: string | null;
    redirectUrlButton: string | null;
    buttonText: string | null;
    priceLabel: string;
    priceDisclaimer: string;
  };
}
// Generated by https://quicktype.io

export interface FormsSubmitClientRequest {
  lead: {
    firstName: string;
    lastName: string;
    email: string;
    address?: string;
    phoneNumber?: string;
    additionalNotes?: string;
    businessName?: string;
    calendlyUrl?: string;
  };
  answers: Record<string, number | number[] | string>;
  clientKey: string;
  demoSubmission?: boolean;
  wcToken?: string;
}

export interface FormsGetFormTokenResponse {
  data: {
    token: string | null;
  };
}
